import { Link, graphql } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import { SEO as Seo } from "../components/seo"
// import Img from "gatsby-image"
import { GatsbyImage } from "gatsby-plugin-image"

const RootContainer = styled.div`
  /* background-image: linear-gradient(
    to right top,
    #d16ba5,
    #c777b9,
    #ba83ca,
    #aa8fd8,
    #9a9ae1,
    #8aa7ec,
    #79b3f4,
    #69bff8,
    #52cffe,
    #41dfff,
    #46eefa,
    #5ffbf1
  ); */
  height: 100%;
`

const HomeContent = styled.div`
  /* color: #fae9da; */
  height: 100%;
`

export default function Home({ data }) {
  console.log(data)
  return (
    <RootContainer id="homePage">
      <Seo title="home page" />
      <Helmet>
        <title>首页</title>
        <meta
          property="siteurl"
          content={`${data.site.siteMetadata.siteUrl}`}
        />
      </Helmet>

      <div className="home-fullimage">
        <div className="home-fullimage__background">
          <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} style={{ height: "100%", width: '100%' }} />
          {/* <Img
            fluid={{ ...data.file.childImageSharp.fluid }}
            style={{ height: "100%" }}
          /> */}
        </div>
        <div className="home-fullimage__overlay"></div>
        <div className="home-fullimage__content">
          <HomeContent>
            <div>xzd.co</div>
            <div>
              <Link to="/blog/">blog</Link>
            </div>
          </HomeContent>
        </div>
      </div>
    </RootContainer>
  )
}

// max-langelott-d3_cFMe97Ec-unsplash
export const query = graphql`
  query {
    file(
      relativePath: {
        eq: "blog/2022/hussan-amir-h0DK5BIGBEg-unsplash.jpg"
      }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        # fluid(maxWidth: 1400, toFormat: WEBP) {
        #   ...GatsbyImageSharpFluid
        # }

        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    site {
      siteMetadata {
        title
        description
        siteUrl
        lang
      }
    }
  }
`
